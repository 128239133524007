<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Products</h2>
            <ul>
                <li><a routerLink="/Home">Home </a></li>
                <li class="active">Products</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-blog-area ptb-100">
    <div class="comming-soon-section">
        <div class="container-fluid p-0">
            <div class="row">
               
               <div class="col-xl-6 col-lg-6">
                   <div class="comming-soon-item">
                     <div class="content">
                         <div class="logo">
                             <img src="assets/img/logo.png" alt="">
                         </div>
                         <h2 class="title">Coming  Soon <img src="assets/img/home-six/cmng-soon/icon-promotion.png" alt=""></h2>
                         <p>We’ll be here soon with more information about our futuristic products.</p>
                     </div>
                     <div class="subscribe-form style-01">
                         <p>We are about to go live so watch this space!</p>
                         <div class="form-group">
                             <input type="text" name="fname" placeholder="Enter Email" class="form-control" required="" aria-required="true">
                             <div class="btn-wrapper">
                               <a href="#0" class="boxed-btn">Subscribe now <i class="icon-paper-plan"></i></a>
                             </div>
                         </div>
                     </div>
                     <ul class="social-area">
                         <p>Connect With Us:</p>
                            <li><a href="https://www.facebook.com/profile.php?id=61554632971539" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="https://twitter.com/Arkitin_" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="https://www.instagram.com/_arkitin/ " target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/arkitin/about/ " target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                     </ul>
                   </div>
               </div>
            </div>
        </div>
     </div>
</section>
