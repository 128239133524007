import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactform!: FormGroup;
    userData: any;

    constructor(public formBuilder: FormBuilder, public api: ApiService) { }

    ngOnInit(): void {
        this.contactform = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', Validators.required],
            organization: ['', Validators.required],
            subject: ['', Validators.required],
            message:['',Validators.required]
        });
    }

    onSubmit() {
        if (this.contactform.invalid) {
            return;
        }
        const userData = this.contactform.value;

        this.api.contact(userData).subscribe(
            (response: any) => {
                console.log('data sent sucess')
                this.contactform.reset();
            },
            (error: any) => {
                console.error('error in sending data', error);
            }
        )
    }

}
