<section class="banner-area" id="banner-area1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content" id="bannercontent">
                            <h1>Unleash the technology beyond the world with Arkitin</h1>
                            <p>In our relentless pursuit of innovation from surface to space, let's reshape the
                                ever-changing tech terrain</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn">Contact Us</a>
                                <a routerLink="/services" class="box-btn border-btn">Know more</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>
<!--------------------------services---------------------->
<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h1>What We Do</h1>
            <p id="we-do">From earth to beyond time and space, Arkitin envisions to build a futuristic environment where
                technology automatically identifies from complexities to problems and develop solutions with the magic
                of IT & IoT powered by AI & Machine Learning.</p>
        </div>
        <!-- <-----------------Services---------------->
        <div class="row">
            <h2 class="service">Services</h2>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/image/icon/Development-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">Development</h3>
                        </a>

                        <p>We specialize in delivering a diverse array of development services of solutions tailored to
                            different needs of organizations, covering a wide spectrum of domains within the dynamic
                            tech landscape.</p>
                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/image/icon/ITES-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">IT Enabled Services (ITES)</h3>
                        </a>

                        <p>From efficient technical and customer support, our offerings
                            leverage cutting-edge technology to enhance operational excellence and deliver tailored
                            solutions for all sectors.</p>

                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/image/icon/IT_and_Software_Consulting-removebg-preview.png" alt="service"
                            class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">IT & Software Consulting </h3>
                        </a>

                        <p>We provide comprehensive IT and software consulting services, offering strategic guidance and
                            expertise to optimize technology for both private and public entities including corporates
                            and governments.</p>
                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/image/icon/IoT-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">Internet of Things (IoT) </h3>
                        </a>

                        <p>We offer a variety of IoT services where we strive to unlock the transformative potential of
                            connected technologies for businesses across various industries to empower them.</p>
                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>

        </div>
        <!-- <---------------------------------------Technology----------------------------------------->
        <h2 class="service1">Technology</h2>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/image/icon/IT_Automation-removebg-preview.png" alt="service"
                            class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">IT Automation</h3>
                        </a>

                        <p class="tech">We specialize in IT Automation Services, streamlining and enhancing operational
                            efficiency
                            through cutting-edge technologies to reduce manual intervention, and ensure a more agile and
                            responsive IT environment. </p>
                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/image/icon/Robotics-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">
                                <h3 id="ser_item"> Robotics</h3>
                            </h3>
                        </a>

                        <p class="tech">We provide vast range of offerings to advance the use of robotics with updated
                            technology to drive innovation, efficiency and compassion into a world where humans coexist
                            with robots without fear or hesitation.</p>
                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/image/icon/AI_and_Machine_Learning-removebg-preview.png" alt="service"
                            class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">AI & Machine Learning</h3>
                        </a>

                        <p class="tech">Our proficiency includes seamless integration, continuous improvement, and
                            personalized
                            recommendations, empowering clients to leverage AI and Machine Learning for impactful custom
                            solutions.</p>

                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="../../../../assets/image/icon/HomeandIndustrialAutomation-removebg-preview.png"
                            alt="service" class="we_do_img" />
                    </div>

                    <div class="service-content">
                        <a routerLink="/services">
                            <h3 id="ser_item">
                                <h3 id="ser_item1">Home & Industrial Automation</h3>
                            </h3>
                        </a>

                        <p class="tech">Our Automation suite delivers smart living, industrial optimization, and
                            enhanced security. Tailored for residential, industrial, and commercial needs, our
                            centralized solutions ensure efficiency and convenience.</p>
                        <a routerLink="/services" class="line-bnt"><i class='bx bx-chevron-down'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-----------------------who we are----------------------->
<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2 id="who1">Who We Are</h2>
                        <div class="content1">
                            <p id="who-we1">Arkitin is a visionary technology firm aspiring to
                                push
                                boundaries and create solutions that inspire change while nurturing the technology we
                                depend
                                on.</p>
                            <p id="who-we1">Arkitin envisions redefining, refining and innovating existing technologies
                                to revive
                                Earth
                                and reach beyond space.</p>
                            <p id="who-we1">Our culture fosters innovation to empower every individual to develop
                                solutions and
                                technologies that build our future.</p>
                        </div>

                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div>
                    <img src="assets/image/HomePage/whoweare.jpeg" alt="company" class="who_we_are" />
                </div>
            </div>
        </div>
    </div>
</section>

<!---------------------why choose arkitin----------------->
<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h1 id="text1">Why Choose Arkitin?</h1>
            <p>Arkitin stands out for its distinctive solutions, affordability and timely delivery which empower clients
                who are in need of ideas and solutions to make their business and product.</p>
        </div>

        <div class="row">
            <div class="col-lg-1 col-sm-6 col-md-1">
            </div>
            <div class="col-lg-2 col-sm-6 col-md-2" id="why">
                <div class="single-process" id="arkitin-model1">
                    <div class="icon">
                        <img src="assets/image/choose-arkitin/futuristic.png" alt="process" class="model_img" />
                        <span><img src="../../../../assets/image/about/doublearrow1.png" alt="shape" /></span>
                        <span><img src="../../../../assets/image/about/doublearrowup1.png " alt="shape"
                                id="img1" /></span>
                    </div>

                    <div class="content">
                        <h3 id="wha">Futuristic</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 col-md-2">
                <div class="single-process" id="arkitin-model2">
                    <div class="icon">
                        <img src="assets/image/choose-arkitin/User Friendly.png" alt="process" class="model_img" />
                        <span><img src="../../../../assets/image/about/doublearrow1.png" alt="shape" /></span>
                        <span><img src="../../../../assets/image/about/doublearrowup1.png " alt="shape"
                                id="img1" /></span>
                    </div>

                    <div class="content">
                        <h3 id="wha1">UserFriendly</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 col-md-2">
                <div class="single-process" id="arkitin-model3">
                    <div class="icon">
                        <img src="assets/image/choose-arkitin/affordable.png" alt="process" class="model_img" />
                        <span><img src="../../../../assets/image/about/doublearrow1.png" alt="shape" /></span>
                        <span><img src="../../../../assets/image/about/doublearrowup1.png " alt="shape"
                                id="img1" /></span>
                    </div>

                    <div class="content">
                        <h3 id="wha2">Affordable</h3>

                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 col-md-2">
                <div class="single-process" id="arkitin-model4">
                    <div class="icon">
                        <img src="assets/image/choose-arkitin/Reliable.png" alt="process" class="model_img" />
                        <span><img src="../../../../assets/image/about/doublearrow1.png" alt="shape" /></span>
                        <span><img src="../../../../assets/image/about/doublearrowup1.png " alt="shape"
                                id="img1" /></span>
                    </div>

                    <div class="content">
                        <h3 id="wha3">Reliable</h3>

                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6 col-md-2">
                <div class="single-process" id="arkitin-model5">
                    <div class="icon">
                        <img src="assets/image/choose-arkitin/Scalable.png" alt="process" class="model_img" />
                    </div>

                    <div class="content">
                        <h3 id="wha3">Scalable</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6 col-md-1">
            </div>
        </div>
    </div>
</section>

<!---------------------Arkitin Model----------------->
<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Arkitin Model</h1>
        </div>
        <div class="row">
            <div class="col-lg-6" id="ar-model">
                <div>
                    <img src="assets/image/HomePage/Approach Model.jpeg" alt="choose" class="why_choose_us" />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- -----------------Industry-------------------- -->
<section class="home-team-area ptb-100" id="industry_background">
    <div class="container">
        <div class="section-title">
            <h1>Industry</h1>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Banking _ Finance.jpeg" alt="team" class="industry_img" />
                        </div>
                        <div class="content text-center">
                            <h3>Banking & Finance</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Manufacturing.jpeg" alt="team" class="industry_img" />
                        </div>
                        <div class="content text-center">
                            <h3>Manufacturing</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Telecom.jpeg" alt="team" class="industry_img" />
                        </div>

                        <div class="content text-center">
                            <h3>Telecom</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Energy utilities.jpeg" alt="team" class="industry_img" />
                        </div>

                        <div class="content text-center">
                            <h3>Energy & Utilities</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Infrastructure.jpeg" alt="team" class="industry_img" />
                        </div>

                        <div class="content text-center">
                            <h3>Infrastructure</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Logistics.jpeg" alt="team" class="industry_img" />
                        </div>

                        <div class="content text-center">
                            <h3>Logistics</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Media and Entertainment.jpeg" alt="team"
                                class="industry_img" />
                        </div>

                        <div class="content text-center">
                            <h3>Media & Entertainment</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Travel and hospitality.jpeg" alt="team"
                                class="industry_img" />
                        </div>

                        <div class="content text-center">
                            <h3>Travel & Hospitality</h3>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<!---------------------Numbers Tell Our Story----------------->
<section class="story ptb-100">
    <div class="counter-section-area">
        <div class="section-title">
            <h1>Numbers Tell Our Story</h1>
        </div>
        <div class="container">
            <div class="counter-section-inner">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6">
                        <div class="single-counterup-01 style-04">
                            <div class="content">
                                <div class="count-wrap"><span class="count-num">2</span></div>
                                <h4 class="title">Years of Experience</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-counterup-01 style-02">
                            <div class="content">
                                <div class="count-wrap"><span class="count-num">34</span></div>
                                <h4 class="title">Clients</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-counterup-01 style-04">
                            <div class="content">
                                <div class="count-wrap"><span class="count-num">27</span></div>
                                <h4 class="title">Experts</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-counterup style-01">
                            <div class="content">
                                <div class="count-wrap"><span class="count-num">5</span></div>
                                <h4 class="title">Partners</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!---------------------Testimonials----------------->
<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <h1>Testimonials</h1>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/ConsultCyclelogo.png" alt="img" class="consult_cycle">
                        <p>We chose to partner with Arkitin for its unbeatable affordability and unique approach to
                            technology solutions. What sets Arkitin apart, however, is not just their
                            cost-effectiveness, but their exceptional problem-solving skills and vibrant team effort.
                            From the outset, Arkitin demonstrated a futuristic perspective, understanding our needs and
                            challenges with a forward-thinking mindset.</p>
                        <a href="https://www.consultcycle.com/" target="_blank" id="title1">
                            <h3>Consult Cycle</h3>
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/Standpoint_Social_-_Navbar_logo_png-removebg-preview (1).png" alt="img"
                            class="consult_cycle">
                        <p>Arkitin not only met but exceeded our expectations in terms of project timelines. Their
                            ability to deliver results within the agreed-upon timeframe was crucial for our operations,
                            demonstrating a level of reliability that is truly commendable. Their attention to detail,
                            creativity,
                            and adherence to our project specifications were evident throughout the process.</p>
                        <a href="https://www.standpointsocial.com/" target="_blank" id="title1">
                            <h3>StandPoint Social</h3>
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/Zeqube logo.png" alt="img" id="zeqube">
                        <p>We partnered with Arkitin for a digital transformation project, and the results were beyond
                            our expectations. The team's strategic approach, coupled with their creative problem-solving
                            skills, helped us achieve our goals efficiently.The Young Arkitin Team is a reliable and
                            forward-thinking technology partner that we would recommend without hesitation.</p>
                        <a href="https://zeqube.com/" target="_blank" id="title1">
                            <h3>ZeQube</h3>
                        </a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/Kreativ3d-01-removebg-preview.png" alt="img" id="Kreativ3D">
                        <p>The impact of Arkitin's solutions on our company's IT infrastructure has been remarkable. The
                            team's ability to understand our unique needs and tailor their services accordingly is
                            commendable. They not only met but exceeded our expectations. Arkitin is a trusted partner,
                            and we look forward to exploring more opportunities together.</p>
                        <a href="https://kreative3d.com/" target="_blank" id="title1">
                            <h3 id="testmoni1">Kreativ3D</h3>
                        </a>
                    </div>
                </ng-template>


            </owl-carousel-o>
        </div>
    </div>
</section>

<!---------------------Contact Us----------------->
<section class="home-contact-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Contact Us</h1>
            <h4>Let us build your business & product together!</h4>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/image/HomePage/contact-us.jpeg" alt="contact" class="ask_us">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form [formGroup]="contactform" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name"  formControlName="name" id="name" class="form-control" required
                                        placeholder="Name" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email"  id="email" formControlName="email" class="form-control" required
                                        placeholder="Email " />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="number" name="phone" id="phone" formControlName="phone" required
                                        class="form-control" placeholder="Mobile" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="organization" id="organization" class="form-control" formControlName="organization" required
                                        placeholder="Organization" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="subject" id="subject" class="form-control" formControlName="subject" required
                                        placeholder="Subject" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" formControlName="message" cols="10" rows="2"
                                        required placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="file" class="form-control" id="file" required placeholder="Attachment">
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" onclick="onSubmit()" >Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>