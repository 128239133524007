<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/Home">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area1">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="company-content">
                    <div class="company-tittle">
                        <!-- <span>Our Company</span> -->
                        <h2>About Us</h2>
                        <p>Arkitin is a forward-thinking tech company with a bold vision of pushing boundaries and
                            driving change. We firmly believe in the transformative power of innovation and
                            empowering our global team to develop products that anticipate tomorrow's needs. With a strong focus on our clients, we offer personalized solutions that add a
                            real value to the table.</p>
                        <p>Arkitin is also unwavering in its support for startups and individuals, providing them with
                            the technological foundation necessary for success. We support in developing MVPs for incubated startups and monetize on equity basis. Our extensive portfolio covers software and
                            hardware development, AI & ML, showcasing our commitment to excel in ITES, Robotics,
                            IoT, and Networking.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div >
                    <img src="assets/image/about/about-us-side-img.png"  class="about_image">
                    <img src="assets/image/about/aboutusmobile.jpg" class="about_image2">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-company-area1 bg-color" id="home-company-area2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Our goals</h2>
                        <p>Our aim is not only to innovate but to do so responsibly, leaving a positive imprint on the world.
                            Moreover, Arkitin isn't content with just its own success. We provide the necessary
                            technological foundation and support for businesses, helping them thrive and realize their
                            innovative visions. </p>
                        <p>In a world where the tech landscape is in a constant state of evolution, Arkitin invites you
                            to join us in shaping a future where innovation knows no bounds. Together, we can redefine
                            what's possible, and together, we can continue to push the limits of technology,
                            transforming the world in the process.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div >
                    <img src="assets/image/about/about-us-side-img1 (1).png"  class="about_image">
                    <img src="assets/image/about/ourgolasmobile.png" class="about_image2">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw"
            title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div> -->

<!-- <section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2>We Have Also Some Features That Provided by Aiva</h2>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt consectetur, beatae quod
                            eaque reprehenderit non ab quibusdam doloribus voluptatibus! Voluptatum aspernatur quasi id
                            dolore doloremque quo vero</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Freelancing Traning Course</li>
                        <li><i class="flaticon-correct"></i> Technological Consultation</li>
                        <li><i class="flaticon-correct"></i> Monthly Paid Workspace</li>
                        <li><i class="flaticon-correct"></i> IT Learning Institute</li>
                        <li><i class="flaticon-correct"></i> Digital Marketing</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <h1>Testimonials</h1>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/Consult Cycle logo.png" alt="img" class="consult_cycle">
                        <p>We chose to partner with Arkitin for its unbeatable affordability and unique approach to
                            technology solutions. What sets Arkitin apart, however, is not just their
                            cost-effectiveness, but their exceptional problem-solving skills and vibrant team effort.
                            From the outset, Arkitin demonstrated a futuristic perspective, understanding our needs and
                            challenges with a forward-thinking mindset.</p>
                            <a href="https://www.consultcycle.com/" target="_blank" id="title1"><h3>Consult Cycle</h3></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/Standpoint_Social_-_Navbar_logo_png-removebg-preview (1).png" alt="img" class="consult_cycle">
                        <p>Arkitin not only met but exceeded our expectations in terms of project timelines. Their
                            ability to deliver results within the agreed-upon timeframe was crucial for our operations,
                            demonstrating a level of reliability that is truly commendable. Their attention to detail, creativity,
                            and adherence to our project specifications were evident throughout the process.</p>
                            <a href="https://www.standpointsocial.com/" target="_blank" id="title1"><h3>StandPoint Social</h3></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/Zeqube logo.png" alt="img"  id="zeqube">
                        <p>We partnered with Arkitin for a digital transformation project, and the results were beyond
                            our expectations. The team's strategic approach, coupled with their creative problem-solving
                            skills, helped us achieve our goals efficiently.The Young Arkitin Team is a reliable and
                            forward-thinking technology partner that we would recommend without hesitation.</p>
                            <a href="https://zeqube.com/" target="_blank" id="title1"><h3 id="testmoni1">ZeQube</h3></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/image/Kreativ3d-01-removebg-preview.png" alt="img" id="Kreativ3D">
                        <p>The impact of Arkitin's solutions on our company's IT infrastructure has been remarkable. The
                            team's ability to understand our unique needs and tailor their services accordingly is
                            commendable. They not only met but exceeded our expectations. Arkitin is a trusted partner,
                            and we look forward to exploring more opportunities together.</p>
                            <a href="https://kreative3d.com/" target="_blank" id="title1"><h3 class="testmoni2" id="testmoni1">Kreativ3D</h3></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>