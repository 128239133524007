<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/Home"><img src="assets/image/Arkitin-removebg-preview.png" alt="logo"
                                class="footer_logo" /></a>
                    </div>
                    <p>Arkitin is a visionary tech company committed to driving innovation and empowering global teams
                        to create transformative products. It aims to redefine possibilities and push the limits of
                        technology for a better world.</p>
                    <div class="subscribe">
                        <form class="newsletter-form" [formGroup]="subscribeForm" >
                        <h4>Join Newsletter</h4>
                        <!-- <form class="newsletter-form" [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" method="post" action="https://script.google.com/macros/s/AKfycbwkb4pHI-oIpkWB2-FLyuwsfEVvZBdlE_f3MRrU_32B0Flawlh1-_-l7Ovr7aSw8Pkm/exec" name="subscribe-form" > -->
                            <input type="email" id="email" name="email" class="form-control" formControlName="email" placeholder="Your Email"
                                required autocomplete="off">
                            <button class="box-btn" type="submit"  onclick="onSubmit()">Subscribe</button>
                        </form>
                    </div>

                    <ul class="social">
                        <li><a href="https://www.facebook.com/profile.php?id=61554632971539" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/Arkitin_" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href=" https://www.instagram.com/_arkitin/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/arkitin/about/" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Services</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/services">Development</a></li>
                        <li><a routerLink="/services"> IT Enabled Services (ITES)</a></li>
                        <li><a routerLink="/services">IT & Software Consulting </a></li>
                        <li><a routerLink="/services">Internet of Things (IoT)</a></li>
                        <li><a routerLink="/services">IT Automation</a></li>
                        <li><a routerLink="/services">Robotics</a></li>
                        <li><a routerLink="/services">AI and Machine learning</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>
                    <ul class="footer-list">
                        <li><a routerLink="/Home">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/partners">Partners</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li><a routerLink="/newsMedia">News & Media</a></li>
                        <li><a routerLink="/career">Careers</a></li>
                        <li><a routerLink="/referral">Referrals</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>
                    <ul class="footer-list foot-social">
                        <li><a href="tel:+91 97500 05058"><i class="bx bx-phone"></i>+91 97500 05058</a></li>
                        <li><a href="mailto:contact@arkitin.com"><i class="bx bxs-envelope"></i>contact@arkitin.com</a></li>
                        <li><i class="bx bxs-map"></i> 2nd Floor, Powertec House, New No: 4, 5th Main Road, Vijaya
                            Nagar, Velachery, Chennai, Tamil Nadu - 600042.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 text-center">
                    <p>©2023 Arkitin. All rights are reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>