import { Component } from '@angular/core';

@Component({
  selector: 'app-news-media',
  templateUrl: './news-media.component.html',
  styleUrls: ['./news-media.component.scss']
})
export class NewsMediaComponent {

}
