<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/Home">Home </a></li>
                <li class="active">Services</li>
            </ul>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <!-- <-----------------Services---------------->
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-service1">
                    <div class="service-img">
                        <img src="assets/image/icon/Development-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>
                    <div class="service-content">
                        <h3>Development</h3>
                        <ul class="list_items">
                            <li>Website Development</li>
                            <li>Mobile App Development</li>
                            <li>Application Development</li>
                            <li>Customized Enterprise Solutions</li>
                            <li>ERP & CRM</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service1">
                    <div class="service-img">
                        <img src="assets/image/icon/ITES-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>
                    <div class="service-content">
                        <h3>IT Enabled Services (ITES)</h3>
                        <ul class="list_items">
                            <li>IT Infrastructure</li>
                            <li>IT Support & Maintenance</li>
                            <li>Big Data Analytics</li>
                            <li>Data Lake & Warehousing</li>
                            <li>Cloud Services & Networking</li>
                            <li>BPO & KPO</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service1">
                    <div class="service-img">
                        <img src="assets/image/icon/IT_and_Software_Consulting-removebg-preview.png" alt="service"
                            class="we_do_img" />
                    </div>
                    <div class="service-content">
                        <h3>IT & Software Consulting </h3>
                        <ul class="list_items">
                            <li>IT Advisory</li>
                            <li>Business Process Automation</li>
                            <li>Digital Risk Management</li>
                            <li>System Integration</li>
                            <li>Business Intelligence</li>
                            <li>IT Compliance and Regulatory</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service1">
                    <div class="service-img">
                        <img src="assets/image/icon/IoT-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>
                    <div class="service-content">
                        <h3>Internet of Things (IoT) </h3>
                        <ul class="list_items">
                            <li>Industrial IoT</li>
                            <li>Retail IoT</li>
                            <li>Healthcare IoT</li>
                            <li>Environmental IoT</li>
                            <li>Asset Tracking and Management</li>
                            <li>Energy Management System (EMS)</li>
                            <li>Billing Management System (BMS)</li>
                            <li>Installation & Tech Support</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
        </div> 
        <!-- <---------------------------------------Technology----------------------------------------->
        <!-- <h2 class="service1">Technology</h2> -->
        <div class="row" id="tech" >
            <div class="col-lg-3 col-sm-6" >
                <div class="single-service1">
                    <div class="service-img">
                        <img src="assets/image/icon/IT_Automation-removebg-preview.png" alt="service"
                            class="we_do_img" />
                    </div>
                    <div class="service-content" >
                        <h3>IT Automation</h3>
                        <ul class="list_items">
                                <li>Business Process Automation</li>
                                <li>Infrastructure Automation</li>
                                <li>DevOps Automation</li>
                                <li>Data Management Automation</li>
                                <li>Industrial Automation</li>
                                <li>Cloud Automation</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service1">
                    <div class="service-img">
                        <img src="assets/image/icon/Robotics-removebg-preview.png" alt="service" class="we_do_img" />
                    </div>
                    <div class="service-content">
                        <h3> Robotics</h3>
                        <ul class="list_items">
                                <li>Industrial Robotics</li>
                                <li>Robotic Software Development</li>
                                <li>Autonomous Vehicles and Drones</li>
                                <li>Custom Robotic Solutions</li>
                                <li>Robotics Integration Services</li>
                                <li>Maintenance and Support for Robotics Systems</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service1">
                    <div class="service-img">
                        <img src="assets/image/icon/AI_and_Machine_Learning-removebg-preview.png" alt="service"
                            class="we_do_img" />
                    </div>
                    <div class="service-content">
                        <h3> AI & Machine Learning</h3>
                        <ul class="list_items">
                                <li>AI Strategy and Consulting</li>
                                <li>Machine Learning Model Development</li>
                                <li>Natural Language Processing</li>
                                <li>AI-powered Recommendation Systems</li>
                                <li>AI Model Deployment and Integration</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-service1">
                    <div class="service-img">
                        <img src="../../../../assets/image/icon/HomeandIndustrialAutomation-removebg-preview.png" alt="service"
                            class="we_do_img" />
                    </div>
                    <div class="service-content">
                        <h3>Home & Industrial Automation</h3>
                        <ul class="list_items">
                                <li>Home Automation </li>
                                <li>Industrial Automation</li>
                                <li>Building Management System</li>
                                <li>Ambience Control System</li>
                                <li>CCTV & Access Control System</li>
                        </ul>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!------------------------------------------------------------industry---------------------------------------------------- -->
<section class="home-team-area ptb-100" id="industry_background">
    <div class="container">
        <div class="section-title">
            <h2>Industry</h2>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Banking _ Finance.jpeg" alt="team" class="industry_img"/>
                        </div>
                        <div class="content text-center">
                            <h3>Banking & Finance</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Manufacturing.jpeg" alt="team" class="industry_img"/>
                        </div>
                        <div class="content text-center">
                            <h3>Manufacturing</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Telecom.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Telecom</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Energy utilities.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Energy & Utilities</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Infrastructure.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Infrastructure</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Logistics.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Logistics</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Media and Entertainment.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Media & Entertainment</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Travel and hospitality.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Travel & Hospitality</h3>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>