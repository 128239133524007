import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-refferal',
  templateUrl: './refferal.component.html',
  styleUrls: ['./refferal.component.scss']
})
export class RefferalComponent implements OnInit {
  refferalForm!: FormGroup;
  userData: any;

  constructor(public formBuilder: FormBuilder, public api: ApiService) { }

  ngOnInit(): void {
    this.refferalForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      company_name: ['', Validators.required],
      ref_email: ['', Validators.required],
      ref_phone: ['', Validators.required],
      ref_loc: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.refferalForm.invalid) {
      return;
    }
    const userData = this.refferalForm.value;

    this.api.refferal(userData).subscribe(
      (response: any) => {
        console.log('data sent sucess')
        this.refferalForm.reset();
      },
      (error: any) => {
        console.error('error in sending data', error);
      }
    )
  }
}
