<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/Home">Home </a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact" id="contact1">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Email</h3>
                        <p id="our1">contact@arkitin.com </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Address</h3>
                        <p id="our1">2nd Floor, Powertec House, New No: 4, 5th Main Road, Vijaya
                            Nagar, Velachery, Chennai, <br>Tamil Nadu - 600042</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact" id="contact1">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Phone</h3>
                        <p id="our1">+91 97500 05058</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <h4>Let us build your business & product together!</h4>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form [formGroup]="contactform" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name"  formControlName="name" id="name" class="form-control" required
                                        placeholder="Name" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email"  id="email" formControlName="email" class="form-control" required
                                        placeholder="Email " />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="number" name="phone" id="phone" formControlName="phone" required
                                        class="form-control" placeholder="Mobile" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="organization" id="organization" class="form-control" formControlName="organization" required
                                        placeholder="Organization" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="subject" id="subject" class="form-control" formControlName="subject" required
                                        placeholder="Subject" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" formControlName="message" cols="10" rows="2"
                                        required placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="file" class="form-control" id="file" required placeholder="Attachment">
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" onclick="onSubmit()" >Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                    <div>
                        <img src="assets/image/HomePage/contact-us.jpeg" alt="" class="contact_us">
                    </div>
            </div>
        </div>
    </div>
</section>