import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // private scriptURL = 'https://script.google.com/macros/s/AKfycbwkb4pHI-oIpkWB2-FLyuwsfEVvZBdlE_f3MRrU_32B0Flawlh1-_-l7Ovr7aSw8Pkm/exec';

  private scriptURL = '';

  constructor( public http :HttpClient ) { }

  contact(userData: any){
    return this.http.post<any>('http://arkitinemail-env.eba-dgpy9ngb.ap-south-1.elasticbeanstalk.com/api/save',userData);
  }

  refferal(userData: any){
    return this.http.post<any>('http://arkitinemail-env.eba-dgpy9ngb.ap-south-1.elasticbeanstalk.com/api/referral',userData);
  }

  newsletter(userData: any){
    return this.http.post<any>('http://arkitinemail-env.eba-dgpy9ngb.ap-south-1.elasticbeanstalk.com/api/joinNews',userData);
  }

  submitForm(formData: FormData){
      return this.http.post(this.scriptURL, formData).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse){
     return throwError(error.message || 'Server Error');
  }
}
