<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Partners</h2>
            <ul>
                <li><a routerLink="/Home">Home </a></li>
                <li class="active">Partners</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="home-blog-area ptb-100">
    <div class="comming-soon-section">
        <div class="container-fluid p-0 ">
            <div class="row">
                
               <div class="col-xl-6 col-lg-6" >
                   <div class="comming-soon-item">
                     <div class="content">
                         <div class="logo">
                              <img src="assets/img/logo.png" alt="">
                         </div>
                         <h2 class="title">Coming Soon</h2>
                         <p>We’ll be here soon with more information about our partners.</p>
                     </div>
                     <div class="subscribe-form style-01">
                         <p>We are about to go live so watch this space!</p>
                         <div class="form-group">
                             <input type="text" name="fname" placeholder="Enter Email" class="form-control" required="" aria-required="true">
                             <div class="btn-wrapper">
                               <a href="#0" class="boxed-btn">Subscribe now <i class="icon-paper-plan"></i></a>
                             </div>
                         </div>
                     </div>
                     <ul class="social-area">
                         <p>Connect With Us:</p>
                            <li><a href="https://www.facebook.com/profile.php?id=61554632971539" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="https://twitter.com/Arkitin_" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="https://www.instagram.com/_arkitin/ " target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/arkitin/about/ " target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                     </ul>
                   </div>
                   
               </div>
            </div>
        </div>
     </div>
</section>
<section class="home-team-area ptb-100" id="industry_background">
    <div class="container">
        <div class="section-title">
            <h2>Industry</h2>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Banking _ Finance.jpeg" alt="team" class="industry_img"/>
                        </div>
                        <div class="content text-center">
                            <h3>Banking & Finance</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Manufacturing.jpeg" alt="team" class="industry_img"/>
                        </div>
                        <div class="content text-center">
                            <h3>Manufacturing</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Telecom.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Telecom</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Energy utilities.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Energy & Utilities</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Infrastructure.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Infrastructure</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Logistics.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Logistics</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Media and Entertainment.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Media & Entertainment</h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/image/Industry/Travel and hospitality.jpeg" alt="team" class="industry_img"/>
                        </div>

                        <div class="content text-center">
                            <h3>Travel & Hospitality</h3>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>