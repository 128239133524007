<!-- <div class="page-title-area">

    <div class="container">
        <div class="page-title-content">
            <h2>Referrals</h2>
            <ul>
                <li><a routerLink="/Home">Home</a></li>
                <li class="active">Referrals</li>
            </ul>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
        </div>
        <div id="refer1">
            <p>We firmly believe in the power of connection, and we create win-win situations for all our members. We
                invite you to join our exclusive network. By joining us, you become a part of a dynamic community where
                mutual success is guaranteed.</p>
            <div>
                <h2>Earn Up to</h2>
                <h2>10% Reward</h2>
                <h2>For Every Project Referral.</h2>
            </div>
            <h4 id="heading1">Let's explore, earn, and enhance the network</h4>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6" id="referralForm">
                <div class="content">
                    <form [formGroup]="refferalForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" formControlName="name" required
                                        placeholder="Your Name" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" formControlName="email" required
                                        placeholder="Your Email" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="number" name="phone" id="phone" class="form-control" formControlName="phone" required
                                        placeholder="Your Phone" />
                                </div>
                            </div>
                            <h4>Referral details</h4>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="company_name" id="company_name" class="form-control"
                                        required formControlName="company_name" placeholder="Name of the Company" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="ref_email" id="ref_email" class="form-control" required formControlName="ref_email"
                                        placeholder="Email address" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="number" name="ref_phone" id="ref_phone" class="form-control" required formControlName="ref_phone"
                                        placeholder="Phone No" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="ref_loc" id="ref_loc" class="form-control" required formControlName="ref_loc"
                                        placeholder="Location" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="20" rows="3"
                                        required formControlName="message" placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" onclick="onsubmit()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div>
                    <img src="../../../../assets/image/Refferal.jpeg" alt="" class="referral_us">
                </div>
            </div>
        </div>
        <div>
        </div>
        <div class="Conditions">
            <p>*Conditions Apply</p>
            <ol>
                <li>Anyone can participate in this referral procedure.</li>
                <li>Rewards are given based on the size and value of the project.</li>
                <li>The number of proposals per lead generation specialist is unlimited.</li>
                <li>Referred projects must be within the scope of services we offer.</li>
                <li>The information of lead generation experts will be kept private.</li>
                <li>Lead generation experts must not refer to their employer's projects.</li>
            </ol>
        </div>
    </div>
</section> -->


<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Referrals</h2>
            <ul>
                <li><a routerLink="/Home">Home</a></li>
                <li class="active">Referrals</li>
            </ul>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
        </div>
        <div id="refer1">
            <p>We firmly believe in the power of connection, and we create win-win situations for all our members. We
                invite you to join our exclusive network. By joining us, you become a part of a dynamic community where
                mutual success is guaranteed.</p>
            <div>
                <h2>Earn Up to</h2>
                <h2>10% Reward</h2>
                <h2>For Every Project Referral.</h2>
            </div>
            <h4 id="heading1">Let's explore, earn, and enhance the network</h4>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6" id="referralForm">
                <div class="content">
                    <form [formGroup]="refferalForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" formControlName="name"
                                        required placeholder="Your Name" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control"
                                        formControlName="email" required placeholder="Your Email" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="number" name="phone" id="phone" class="form-control"
                                        formControlName="phone" required placeholder="Your Phone" />
                                </div>
                            </div>
                            <h4>Referral details</h4>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="company_name" id="company_name" class="form-control"
                                        formControlName="company_name" required placeholder="Name of the Company" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="ref_email" id="ref_email" class="form-control"
                                        formControlName="ref_email" required placeholder="Email address" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="number" name="ref_phone" id="ref_phone" class="form-control"
                                        formControlName="ref_phone" required placeholder="Phone No" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="ref_loc" id="ref_loc" class="form-control"
                                        formControlName="ref_loc" required placeholder="Location" />
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="20" rows="3"
                                        required formControlName="message" placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" onclick="onsubmit()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div>
                    <img src="../../../../assets/image/Refferal.jpeg" alt="" class="referral_us">
                </div>
            </div>
        </div>
        <div>
        </div>
        <div class="Conditions">
            <p>*Conditions Apply</p>
            <ol>
                <li>Anyone can participate in this referral procedure.</li>
                <li>Rewards are given based on the size and value of the project.</li>
                <li>The number of proposals per lead generation specialist is unlimited.</li>
                <li>Referred projects must be within the scope of services we offer.</li>
                <li>The information of lead generation experts will be kept private.</li>
                <li>Lead generation experts must not refer to their employer's projects.</li>
            </ol>
        </div>
    </div>
</section>