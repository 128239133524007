import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


    subscribeForm!: FormGroup;

    constructor(public formBuilder: FormBuilder, public api: ApiService) { }


    ngOnInit(): void {
        this.subscribeForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }



    // onSubmit() {
    //     if (this.subscribeForm.valid) {
    //         const formData = new FormData();
    //         Object.keys(this.subscribeForm.value).forEach(key => formData.append(key, this.subscribeForm.value[key]));

    //         this.api.submitForm(formData).subscribe(
    //             () => {
    //                 // Handle successful response
    //                 window.location.reload();
    //             },
    //             error => {
    //                 // Handle error response
    //                 console.error('Error!', error);
    //             }
    //         );
    //     }
    // }



    // onSubmit() {
    //     if (this.newsletter.invalid) {
    //         return;
    //     }
    //     const userData = this.newsletter.value;

    //     this.api.newsletter(userData).subscribe(
    //         (response: any) => {
    //             console.log('data sent sucess')
    //             this.newsletter.reset();
    //         },
    //         (error: any) => {
    //             console.error('error in sending data', error);
    //         }
    //     )
    // }
}
